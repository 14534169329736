const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'work') {
    return `/work/${doc.uid}`
  }
  if (doc.type === 'work_category') {
    return `/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}

module.exports = linkResolver
