import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import ViewWork from "../sections/peel-creative/ViewWork";
import { graphql } from 'gatsby'
import SEO from "../components/Seo";

const WorkPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicWork.edges[0].node.data

  return (
    <>
      <PageWrapper>
        <SEO title={document.title.text} description={document.meta_description.text} />

        <ViewWork document={document} />
      </PageWrapper>
    </>
  );
};

export const query = graphql`
  query WorkPageQuery($uid: String) {
    allPrismicWork(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            project_name {
              text
            }
    
            title {
              text
            }
            project_location {
              text
            }
            project_description {
              raw
            }
            meta_description {
              text
            }
            carousel_images {
              featured_image {
                alt
                copyright
                thumbnails
                url(imgixParams: {})
                localFile {
                  childImageSharp {
                    fluid(quality: 75, maxWidth: 1920) {
                      base64
                      srcWebp
                      srcSetWebp
                      originalImg
                      originalName
      }
                  }
                }
                }
            }
          }
        }
      }
    }
  }
`
export default WorkPage
