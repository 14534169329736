import React, { useRef, useEffect, useState, useCallback } from "react";

import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

import Swiper from 'react-id-swiper';

import 'swiper/swiper-bundle.css';


import Img from "gatsby-image"

import { RichText } from 'prismic-reactjs';

import { htmlSerializer } from '../../utils/htmlSerializer'

import RArrow from "../../assets/peel-creative/arrows/right_arrow.svg"
import LArrow from "../../assets/peel-creative/arrows/left_arrow.svg"

const StyledSection = styled(Section)`

min-height: 90vh;
padding-bottom: 0px;

img{
    object-fit: contain !important;
}
`

const SliderControls = styled(Section)`
padding-top: 0px;

.control-box{
    display: inline-flex;
    align-items: center


}

.word-button{
    min-width: 80px;
    cursor: pointer;
}


@media ${device.sm} {
    .word-button{
        display: none;
    }
}


img{
    padding-left: 15px;
    padding-right: 15px;
    height: 25px;
    
}

img:hover{
    cursor: grab;
}

`

const PaddedBox= styled(Box)`
    .gatsby-image-wrapper{

height: 200px;

    }


@media ${device.md} {
    display: flex;
    justify-content: center;
    .gatsby-image-wrapper{
        height: 65vh;
        max-width: 70vw;
    }
}



`
const WorkSlide = ({ image }) => {
    return (
        <>
            <PaddedBox>
                <Img fluid={image} className='w-100' css={{cursorEvents: "none"}}/>
            </PaddedBox>
        </>
    )
}

const LeftText = styled.div`
display: none;

@media ${device.lg} {
    z-index: 5;
    display: block;
}

    position: absolute;
    left: 10vw;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);

`

const RightText = styled.div`
:hover{
    cursor: grab;
}
display: none;

@media ${device.lg} {
    z-index: 5;
    display: block;
}

    position: absolute;
    right: 10vw;
    transform: translateX(50%) translateY(50%) rotate(+90deg);

`

const LeftTextMob = styled.div`
text-align: center;
padding-bottom: 2rem;

@media ${device.lg} {
    display: none;
}

`

const RightTextMob = styled.div`
text-align: center;

padding-top: 2rem;
@media ${device.lg} {
    display: none;
}


`

const SidePanel = styled.div`
min-height: 100vh;
padding-left: 4rem;
padding-right: 4rem;
width: 550px;
background-color: #9fc0c5;
position: fixed;
top: 0px;
right: 0px;
z-index: 9999;

p{
    color: black !important;

}

@media ${device.sm} {
    width: 100vw;
}


.close_text{
    :hover{
        cursor: grab;
    }
    position: absolute;
    top: 45vh;
right: 0px;
transform: rotateZ(90deg);
color: black !important;

}

.info_box{
    padding-top: 10vh;
}



`

const StyledContainer = styled(Container)`

`


const ViewWork = ({document}) => {


    const work_slides = document.carousel_images.map((data) =>
        <div><WorkSlide image={data.featured_image.localFile.childImageSharp.fluid} critical /></div>
    );

    var slide_count = document.carousel_images.length;

    const ref = useRef(null);

    const [currentIndex, updateCurrentIndex] = useState(0);

    const [showResults, setShowResults] = React.useState(false)
    const cycleTab = () => setShowResults(!showResults)



    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    const updateIndex = useCallback(
        () => updateCurrentIndex(ref.current.swiper.realIndex),
        []
    );

    useEffect(() => {
        const swiperInstance = ref.current.swiper;

        if (swiperInstance) {
            swiperInstance.on("slideChange", updateIndex);
        }

        return () => {
            if (swiperInstance) {
                swiperInstance.off("slideChange", updateIndex);
            }
        };
    }, [updateIndex]);



    return (
        <>
            {/* <!-- Hero Area --> */}

            <StyledSection className="position-relative" css={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SidePanel
                    css={{ transform: showResults ? "translateX(0%)" : "translateX(120%)", "transition": "transform 0.25s"}}
                >
                    <Text className='close_text' onClick={cycleTab}>
                        Close

                    </Text>

                    <Container>
                        <div className='info_box'>
                            <div>
                                <Text color="black" css={{ fontWeight: "bold" }}>
                                    Project description
                                </Text>
                                
                                <Text color="black" className='pt-2'>
                                <RichText render={document.project_description.raw} htmlSerializer={htmlSerializer} />

                        </Text>

                            </div>
                            <div className='pt-5'>
                                <Text color="black" css={{ fontWeight: "bold" }}>
                                    Location
                        </Text>
                                <p color="black" className='pt-2'>
                                {document.project_location.text}

                        </p>

                            </div>

                        </div>


                    </Container>

                </SidePanel>
                <LeftText>
                    <Text color="#9fc0c5" css={{ fontWeight: "bold" }}>
                        {document.project_name.text}
                     </Text>
                </LeftText>

                <RightText>
                    <Text color="#9fc0c5" css={{ fontWeight: "bold" }} onClick={cycleTab}>
                        Project Information

            </Text>

                </RightText>

                <StyledContainer fluid>
                    <LeftTextMob>
                        <Text color="#9fc0c5" css={{ fontWeight: "bold" }}>
                        {document.project_name.text}
                     </Text>
                    </LeftTextMob>


                    <Swiper
                        ref={ref}
                    >
                        {work_slides}
                    </Swiper>
                    <RightTextMob>
                        <Text color="#9fc0c5" css={{ fontWeight: "bold" }} onClick={cycleTab}>
                            Project Information

            </Text>
            </RightTextMob>


                </StyledContainer>

            </StyledSection>
            <SliderControls className='text-center'>
                <Title variant="cardBig" color="#9fc0c5" css={{ fontWeight: "unset", display: "inline-block" }} className='control-box'>
                <span className='word-button' onClick={goPrev} style={{textAlign: "right"}}>Previous</span> <img onClick={goPrev} src={LArrow}></img>{currentIndex + 1}/{slide_count}                    <img onClick={goNext} src={RArrow}></img> <span className='word-button' onClick={goNext} style={{textAlign: "left"}}>Next</span>
                </Title>

            </SliderControls>
        </>
    );
};

export default ViewWork;
